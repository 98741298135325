import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { QuizModel } from "@/models";
import { Common } from "../Common";

export class QuizForm {
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(20, { message: "最多輸入20字" }) name = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) beginTime = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) endTime = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) cid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) sid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) clid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) tid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) grade = "";
    level = "";
    unit = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) num = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) active = "1";
    token = window.localStorage.getItem('adminToken') as string;
}

type response = { [key: string]: unknown };

@Component<AdminQuizPaperAddController>({
    components: {
        SideNav, Lightbox
    }
})
export default class AdminQuizPaperAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private quizForm = new QuizForm();
    private cityList: object = {};
    private schoolList: { [key: string]: any } = {};
    private classList: object = {};
    private teacherList: object = {};
    private gradeList: object = {};
    private levelList: object = {};
    private unitList: response = {};
    private identity = window.localStorage.getItem('admin_identity') as string;

    private errorMsg = "";
    private openLB = false;
    private errNo = 0;

    private errorMsgMap: { [key: string]: string } = {
        name: '',
        beginTime: '',
        endTime: '',
        cid: '',
        sid: '',
        clid: '',
        tid: '',
        unit: '',
        level: '',
        grade: '',
        num: '',
        active: '',
    };

    public async created() {
        if (this.identity == "1") {
            this.cityList = await Common.getSchoolCity(); //取得有分校之縣市
        } else {
            const sid = window.localStorage.getItem('admin_sid') as string;
            this.cityList = await Common.getCity(sid); //取得該分校之縣市
            for (const cont of this.cityList as any) {
                this.quizForm.cid = cont.cid;
            }

            const school = window.localStorage.getItem('admin_school') as string;
            this.schoolList[0] = {
                sid: sid,
                name: school,
            };
            this.quizForm.sid = sid;
            this.getClass(sid);
        }
        this.gradeList = await Common.getGrade();
    }

    private async getLevel() {
        this.quizForm.level = '';
        this.quizForm.unit = '';
        const grade = this.quizForm.grade;
        this.levelList = await Common.getLevel(grade);
        this.unitList = {};
    }

    private async getUnit() {
        this.quizForm.unit = '';
        this.unitList = await Common.getUnit();
    }

    // 取得 該縣市的分校列表 
    private async getSchool(cid: string) {
        if (this.identity == "1") {
            this.quizForm.sid = '';
            this.quizForm.clid = '';
            this.quizForm.tid = '';

            this.schoolList = await Common.getSchool(cid);
            this.classList = [];
            this.teacherList = [];
        }
    }

    // 取得 該分校的班級列表
    private async getClass(sid: string) {
        this.quizForm.clid = '';
        this.quizForm.tid = '';

        this.classList = await Common.getClass(1, sid, 0);
        this.teacherList = [];
    }

    // 取得 該班級的老師列表
    private async getTeacher(clid: string) {
        this.quizForm.tid = '';
        this.teacherList = await Common.getTeacher(1, clid, 0);
    }

    private validateAndSubmit() {
        validate(this.quizForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        name: '',
                        beginTime: '',
                        endTime: '',
                        cid: '',
                        sid: '',
                        clid: '',
                        tid: '',
                        unit: '',
                        level: '',
                        grade: '',
                        num: '',
                        active: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const item = JSON.parse(JSON.stringify(this.quizForm));

                    this.errNo = await QuizModel.addPaper(item);
                    Common.checkToken(this.errNo);
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.push("/admin/quiz/paper");
        }
    }
}
